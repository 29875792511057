// Chakra imports
import { Select, Text, Grid, GridItem } from "@chakra-ui/react";
// Custom components
import Card from "./../../../../components/Card/Card";
//import CardBody from "components/Card/CardBody";
//import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { RangeDatepicker } from "chakra-dayzed-datepicker"

/*
const dataPickerConfigs = {
  dateFormat: 'yyyy-MM-dd',
  dayNames: 'abcdefg'.split(''), // length of 7
  monthNames: 'ABCDEFGHIJKL'.split(''), // length of 12
  firstDayOfWeek: 2, // default is 0, the dayNames[0], which is Sunday if you don't specify your own dayNames,
}
*/

const DataSelectorCard = ({ updatefn, fromDate, toDate, customers, customerSelected }) => {
  const [selectedDates, setSelectedDates] = useState([new Date(fromDate), new Date(toDate)]);
  const [selectedCustomer, setSelectedCustomer] = useState(customerSelected);
  

  const updateDates = (dates)=>{
    if (dates.length === 2 && dates[1] != null){
      const fromIsoDate = new Date(dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      const toIsoDate = new Date(dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      updatefn(fromIsoDate,toIsoDate,selectedCustomer);
    }
  }

  const updateCustomer = (event)=>{
    if (selectedDates.length === 2 && selectedDates[1] != null){
      const fromIsoDate = new Date(selectedDates[0].getTime() - (selectedDates[0].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      const toIsoDate = new Date(selectedDates[1].getTime() - (selectedDates[1].getTimezoneOffset() * 60000)).toISOString().split('T')[0];
      updatefn(fromIsoDate,toIsoDate,event.target.value);
    }
  }

  //sort customers by name
  customers.sort((a, b) => a.name.localeCompare(b.name));
 
  return (
    <Card p='16px' >

      <Grid templateColumns='repeat(4, 1fr)' gap={6}>
        <GridItem>
          <Text>Vælg dato interval:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={(e)=>{setSelectedDates(e);updateDates(e);}}
            
            configs={{
              dateFormat: 'dd-MM-yyyy',
            }}
            propsConfigs={{
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  borderColor: "yellow.300",
                  _hover: {
                    background: 'yellow.400',
                  }
                },
                isInRangeBtnProps: {
                  background: "yellow.300",
                  color: "black",
                },
                selectedBtnProps: {
                  background: "yellow.300",
                  color: "black",
                }
              }
            }}
          />

        </GridItem>

        <GridItem>
          <Text>Vælg kunde:</Text>
        </GridItem>
        <GridItem colSpan={3}>
          <Select multiple={false} value={selectedCustomer} onChange={(e) => {setSelectedCustomer(e.target.value);updateCustomer(e)}}>
            <option key={'customer_all'} value=''>Alle</option>
            {customers.map((option) => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
          </Select>

        </GridItem>
        
      </Grid>
    </Card>
  );
};

export default DataSelectorCard;